@font-face {
  font-family: bouncy;
  src: url("bounch-thin.e8aba0be.woff2") format("woff2");
}

body {
  -webkit-text-stroke: 2px black;
  color: #ffde00;
  background-image: url("blue-bg.fe7dfee9.gif");
  background-position-y: bottom;
  background-size: cover;
  margin: 0;
  font-family: bouncy;
}

#container {
  justify-content: center;
  align-items: center;
  display: flex;
}

header {
  padding: 48px;
}

.score {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: flex;
}

#score-container {
  min-width: 350px;
  font-size: 48px;
}

button {
  text-align: center;
  max-width: 120px;
  width: 100%;
  color: #000;
  background-color: #f6e58d;
  border-radius: 1.5rem;
  padding: 1rem;
  font-size: 2rem;
  font-weight: 700;
  text-decoration: none;
  transition: all .5s;
  display: block;
  box-shadow: 0 10px #f9ca24;
}

/*# sourceMappingURL=index.a1ecb960.css.map */
