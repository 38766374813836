@font-face {
  font-family: 'bouncy';
  src: url('./assets/bounch-thin.woff2') format('woff2');
}

body {
  margin: 0;
  font-family: 'bouncy';
  -webkit-text-stroke: 2px black;
  color: #ffde00;
  background-image: url('./assets/blue-bg.gif');
  background-position-y: bottom;
  background-size: cover;
}

#container {
  display: flex;
  align-items: center;
  justify-content: center;
}

header {
  padding: 48px;
}

.score {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

#score-container {
  font-size: 48px;
  min-width: 350px;
}

button {
  padding: 15px;
  font-weight: 700;
  font-size: 2rem;
  text-decoration: none;
  text-align: center;
  transition: all 0.5s ease;
  max-width: 120px;

  display: block;
  width: 100%;
  padding: 1rem;
  border-radius: 1.5rem;
  background-color: #f6e58d;
  color: #000;
  transition: all 0.5s ease 0s;
  box-shadow: 0 10px #f9ca24;
}
